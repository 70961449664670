.client-dashboard {
    color: black!important;
    background-color: white!important;
    height: 100%!important;
}

.client-dashboard-nav {
    display: flex;
    justify-content: space-between;
    padding: 35px 50px;
}

.client-dashboard-nav-header {
    font-size: 30px;
}

.client-dashboard-nav-header span {
    color: #F4A62B;
}

.client-dashboard-nav-header-menu {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.client-dashboard-nav-header-menu-item:hover {
    opacity: 0.6;
}

.client-dashboard-nav-header-menu-item {
    cursor: pointer;
}

.client-dashboard-nav-header-menu-item:first-child {
    padding-right: 30px;
}

.client-dashboard-line {
    display: flex;
    justify-content: center;
    background-color: #F4A62B;
    cursor: pointer;
}

.client-dashboard-line div {
    padding: 6px;
    letter-spacing: 1px;
    font-size: 18px;
    color: black;
}

.client-dashboard-line div:hover {
    opacity: 0.6;
}

.client-dashboard-main {
    margin: 100px auto 100px auto;
    width: 60%;
}

.client-dashboard-main-title {
    padding-bottom: 80px;
    font-size: 32px;
    text-align: center;
}

.client-dashboard-main-options {
    display: flex;
    justify-content: space-between;
}

.client-dashboard-main-options-img {
    cursor: pointer;
    width: 300px;
}

.client-dashboard-data {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    background-color: #F8F9FD;
    border-radius: 5px;
    padding: 20px 0;
    font-size: 16px;
    letter-spacing: 1px;
}

.client-dashboard-data-column-header {
    font-weight: 600;
}

.client-dashboard-data-column-crossroad {
    display: flex;
    align-items: center;
}

.client-dashboard-data-column-crossroad img {
    height: 60px;
}

.client-dashboard-data-column-crossroad div {
    padding-left: 10px;
}

.client-dashboard-line-hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background-color: white;
}