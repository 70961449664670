.form-dropzone-upload {
    border:  2px dashed rgba(34,36,38,.15);
    height: 100px;
    margin-bottom: 15px;
}

.form-dropzone-upload p {
    display: inline;
    width: 100px;
    height: 30px;
    position: relative;
    left: calc(50% - 50px);
    top: calc(50% - 15px);
    color: #696969;
    font-size: 16px;
}

.form-dropzone-upload--active {
    border:  2px dashed darkgray;
}