.admin-settings {
    width: 80%;
    margin: 0 auto;
}

.admin-settings-main {
    display: flex;
}

.admin-settings-header {
    padding-top: 44px;
    margin-bottom: 60px;
}

.admin-settings-header img {
    width: 150px;
}

.admin-settings-side {
    width: 30%;
    margin-right: 5%;
}

.admin-settings-side-back {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 160px;
    margin-bottom: 15px;
}

.admin-settings-side-back img {
    height: 18px;
    margin-right: 5px;
}

.admin-settings-side-back-text {
    text-decoration: none;
    color: 	silver;
}

.admin-settings-side-back-text:hover {
    color:#A9A9A9;
}

.admin-settings-side-main {
    display: flex;
    flex-direction: column;
}

.admin-settings-side-main a {
    width: 280px;
    background-color: #f6f6f6;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    margin: 1px;
    color: gray;
    font-size: 13px;
    cursor: pointer;
}

.admin-settings-side-main a:hover {
    background-color: #ECECEC;
}

.admin-settings-active {
    background-color: #ECECEC!important;
}

.admin-settings-content {
    width: 50%;
}

.admin-settings-content-table-h {
    color: #DCDCDC;
    margin-bottom: 10px;
    font-size: 40px;
    font-family: "HCo Gotham", sans-serif;
    font-weight: 600;
}

.admin-settings-content-table-add {

    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.admin-settings-content-table-add a {
    color: #F4A62B!important;
}

.admin-settings-content-table-add span {
    color: #F4A62B!important;
    cursor: pointer;
}

.admin-settings-table {
    width: 90%;
    border: 1px solid #DCDCDC;
    border-collapse: collapse;
    margin-bottom: 30px;

}

.admin-settings-table td tr {
    width: 0px!important;
}


.admin-settings-table td {
    height: 50px;
    border-bottom: 1px solid #DCDCDC;
    padding-left: 30px;
}


.admin-settings-table-edit {
    color: #F4A62B;
    font-weight: 600;
    font-size: 14px;
    padding-right: 10px;
    text-transform: uppercase;
    text-decoration: none;
}

.admin-settings-table-del {
    color: #F4A62B;
    font-weight: 600;
    font-size: 14px;
    padding-left: 10px;
    text-transform: uppercase;
    text-decoration: none;
}

.admin-settings-table td:last-of-type {
    padding-left: 0px;
    text-align: center;
    width: 150px!important;
}

.admin-settings-table-edit:hover, .admin-settings-table-del:hover, .admin-settings-content-table-add a:hover {
    color: #F4A62B!important;
    opacity: 0.7;
    cursor: pointer;
}