.client-tasks-main {
    width: 60%;
    margin: 80px auto 35px auto;
}

.client-tasks-header {
    margin-bottom: 50px;
}

.client-tasks-header h1 {
    font-size: 40px;
}

.client-tasks-header p {
    font-size: 18px;
    letter-spacing: 1px;
}

.client-tasks-task-ticked {
    height: 48px;
    width: 54px;
}

.client-tasks-task-empty {
    height: 34px;
    margin: 7px 9px;
    width: 36px;
    border-radius: 50%;
    padding: 0px!important;
    border: 1px solid black;
}

.client-tasks-task {
    margin: 50px auto;
}

.client-tasks-task span {
    float: right;
    display: inline-block;
    font-size: 13px;
    opacity: 0.7;
    padding: 5px 5px;
    cursor: pointer;
}

.client-tasks-task span:hover {
    opacity: 0.7;
}

.client-tasks-task-border h4 {
    margin: 0px!important;
}

.client-tasks-task-border {
    border: 2px #E7E7E7 solid;
    border-radius: 10px;
}

.client-tasks-task-flex {
    display: flex;
    align-items: center;
    padding: 8px;
}

.client-tasks-task-border h4 {
    font-size: 20px;
    padding-left: 15px;
    font-weight: 400;
    /*max-width: 80%;*/
    cursor: pointer;
}

.client-tasks-task-border p {
    margin-left: auto;
    padding-right: 10px;
    order: 2;
    font-size: 16px;
    color: #696969!important;
}

.client-tasks-form {
    border: 2px solid #F4A62B !important;
    border-radius: 10px;
    margin-top: 75px;
}

.client-tasks-form #text {
    width: 100%;
    border-radius: 10px!important;
    font-size: 20px;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
}

.client-tasks-form input::placeholder {
    font-size: 20px;
    opacity: 0.7;
}

.client-tasks-form-form {
    padding: 15px 25px;
    border-top: 2px solid #F4A62B;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client-tasks-form button {
    border: none;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #F4A62B;
    cursor: pointer;
}

.client-tasks-form label {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.client-tasks-form label:hover {
    opacity: 0.7;
}

 #file, #filee {
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
    position: absolute;
}

.client-tasks-task-comments {
    border-top: 2px #E7E7E7 solid;
}

.client-tasks-task-comments-pad {
    padding-top: 15px;
}

.client-tasks-task-comments-comment {
    margin: 10px 20px;
    border-radius: 8px;
    border: 1px solid #e7e7f5;
    padding: 15px;
}

.client-tasks-task-comments-comment h4 {
    padding: 0;
    margin-bottom: 10px;
}

.client-tasks-task-comments-comment p {
    margin-top: 5px;
    margin-bottom: 0px;
    cursor: pointer;
}

.client-tasks-task-comments-comment-files {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    //margin-top: 7px;
}

.client-tasks-task-comments-comment-files-flex {
    display: flex;
    margin-top: 5px;
}

.client-tasks-task-comments-comment-files-flex div {
    margin-right: 10px;
    padding: 5px 8px;
    background-color: #EEEDF1;
    border-radius: 5px;
    font-weight: 600;
}

.client-tasks-task-comments-comment-func-flex {
    display: flex;
    //margin-right: 20px;
}

.client-tasks-task-comments-comment-func-flex div {
    float: right;
    display: inline-block;
    font-size: 13px;
    opacity: 0.7;
    padding-right: 5px;
    cursor: pointer;
}

.client-tasks-task-comments-comment-func-flex div:hover {
    cursor: pointer;
}

.client-tasks-task-comments-add {
    border: 2px #e7e7f5 solid;
    border-radius: 10px;
    margin: 20px 20px 20px 20px;
}

.client-tasks-task-comments-add form {
    height: 60px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 5px;
}


.client-tasks-task-comments-add-input {
    border: none;
    padding-left: 15px;
    font-size: 18px;
    width: 100%;
}

.client-tasks-task-comments-add label {
    padding-right: 8px;
    padding-top: 7px;
}

.client-tasks-task-comments-add label:hover {
    opacity: 0.7;
    cursor: pointer;
}

.client-tasks-task-comments-add-input::placeholder {
    font-size: 18px;
    opacity: 0.7;
}

.client-tasks-form-files {
    display: flex;
}

.client-tasks-form-files div {
    margin-right: 10px;
    padding: 5px 8px;
    background-color: #EEEDF1;
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    width: max-content;
    text-align: center;
}

.client-tasks-form-files div i:hover {
    opacity: 0.7;

}

.client-tasks-form-files div i {
    padding-top: 7px;
    cursor: pointer;
}

.client-tasks-form-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.client-tasks-task-comments-add-files {
    display: flex;
    padding: 0px 10px 10px 10px;
}

.client-tasks-task-comments-add-files div {
    margin-right: 10px;
    padding: 5px 8px;
    background-color: #EEEDF1;
    border-radius: 5px;
    font-weight: 600;
}
.client-tasks-task-comments-add-files-f {
    display: flex;
    align-items: center;
}

.close, .download {
    cursor: pointer;
}

.close:hover, .download:hover {
    opacity: 0.8;
}



.delete-task-comment {
    font-size: 16px!important;
    position: relative;
    float: right;
    bottom: 12px;
    left: 12px;
    color: white
}

.client-tasks-task-comments-comment:hover .delete-task-comment {
    color: rgba(0,0,0,.5);
}

.client-tasks-task-edit-form {
    border: none;
    padding-left: 15px;
    font-size: 20px;
    width: 100%;
    font-weight: 400;
}

.client-tasks-comment-edit-form {
    font-size: 16px;
    color: #696969!important;
    margin-top: 5px;
    border: none;
    width: 100%;
    height: 1.4285em;

}

