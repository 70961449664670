.form-header-amp {
    color: #F4A62B;
}

.form-header-back {
    position: relative;
    right: 30%;
}

.form-header-back a {
    color: #696969;
    transition: 0.2s;
    font-size: 16px;
}

.form-header-back a:hover {
    opacity: 0.8;
}

.form-header h1 {
    color: #DCDCDC;
    margin-bottom: 30px;
    font-size: 40px;
    font-family: "HCo Gotham", sans-serif;
    font-weight: 600;
    margin-top: 60px;
}

@media only screen and (max-width: 1700px) {
    .form-header-back {
        right: 15%;
    }
}

@media only screen and (max-width: 1550px) {
    .form-header-back {
        right: 10%;
    }
}

@media only screen and (max-width: 1400px) {
    .form-header-back {
        right: 5%;
    }
}

@media only screen and (max-width: 1260px) {
    .form-header-back {
        right: 0%;
    }
}