.project-journey-header {
    padding: 20px;
    margin-bottom: 30px;
}

.project-journey-header a {
    display: flex;
    align-items: center;
}

.project-journey-header img {
    height: 25px;
    position: relative;
    bottom: 3px;
}

.project-journey-header span {
    font-family: HCo Gotham, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
    cursor: pointer;
    color: #878787;
}

.project-journey-main {
    display: flex;
    height: calc(100% - 115px);
}

.journey-form {
    width: 25%;
    height: 100%;
    margin-right: 100px;
    margin-left: 20px;
    padding: 20px;
}
.journey-form h4 {
    color: #DCDCDC;
    font-family: HCo Gotham, sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 40px;
}
.journey-form label {
    color: #878787!important;
    font-family: HCo Gotham, sans-serif!important;
    font-weight: 400!important;
    font-size: 14px!important;
}


.journey-form hr {
    display: inline-block;
    margin: auto;
    width: 100%;
    height: 1px;
    border: none;
    background-color: rgba(0,0,0, 0.1);
}

.form-switch-label-journey {
    color: #878787!important;
    font-family: HCo Gotham, sans-serif!important;
    font-weight: 400!important;
    font-size: 14px!important;

}

.journey-cont {
    width: calc(50% - 50px);
    margin-right: 100px;
    padding: 20px;
}

.project-journey-main-space-flex {
    display: flex;
    height: calc(100% - 120px);
}

.project-journey-main-space {
    height: 100%;
    width: 100%;
}

.project-journey-main-space h1 {
    color: #DCDCDC;
    font-size: 36px;
    font-family: HCo Gotham, sans-serif;
    padding: 40px 0 20px 0;
}

.journey-cont-item {
    border: #F4A62B 2px solid;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;

}

.journey-cont-item-top {
    padding: 20px;
    color: #F4A62B;
}
.journey-cont-item-top h4 {
    font-family: HCo Gotham, sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
}


.journey-cont-item-bottom {
    background-color: #F4A62B;
    padding: 6px;

}

.journey-cont-item-bottom p {
    text-align: right;
    margin-right: 15px;
    color: white;
    font-family: HCo Gotham, sans-serif;

}

.journey-cont-h {
    color: #878787;
    font-family: HCo Gotham, sans-serif;
    font-size: 18px;
}

.journey-cont-del {
    float: right;
    position: relative;
    bottom: 14px;
    left: 20px;
    color: white;
    display: none;
    cursor: pointer;
}

.journey-cont-item-cont:hover .journey-cont-del {
    color: rgba(0,0,0,.5);
}

.journey-cont-item-bottom img {
    padding-left: 2px;
    height: 16px;
    position: relative;
    top: 4px;
}