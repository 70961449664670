.preview-section {
    padding-top: 120px;
    padding-left: 80px;
}

.preview-header {
    color: white;
    font-size: 36px;
    margin-bottom: 20px;
}

.preview-desc {
    color: white;
    font-size: 16px;

}

.page-preview-comment {
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 16px;
    padding: 8px;
    width: 80%;
}

.preview-comment-functions {
    display: flex;
    margin-top: 5px
}

.preview-comment-functions-item {
    margin-left: 7px;
    font-size: 12px;
    cursor: pointer;
    color:white
}

.preview-comment-functions-item:hover {
    opacity: 0.7;
}
