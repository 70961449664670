.login {
  display: flex;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.login-right-flex {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.login-image-container {
  display: flex;
}

@media (max-width: 600px) {
  .login {
    flex-direction: column;
  }
  .login-right-flex {
    margin-top: 20px;
    width: 100%;
  }
  .login-image-container {
    justify-content: center;
    width: 100%;
  }
}
