.login-form {
  position: relative;
}

.login-description {
  color: #797979;
  margin-bottom: 60px;
  font-weight: 300;
}
.login-heading {
  font-size: 3.8em;
  padding-top: 10px;
  letter-spacing: 0.05em;
  color: #797979;
  opacity: 0.4;
}

.login-heading span {
  margin-top: 5px;
}

.login-heading::before {
  position: absolute;
  height: 10px;
  display: block;
  width: 60px;
  opacity: 0.6;
  background: #797979;
  content: "";
  top: 0;
}

.login-form-image {
  height: 85px;
  margin-bottom: 25px;
}

.login-form input {
  display: flex;
  flex-direction: column;
}

.login-form input {
  width: 360px;
  font-size: 16px;
  margin-bottom: 45px;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.login-form input::placeholder {
  font-family: HCo Gotham, sans-serif;
  font-weight: 600;
  padding-bottom: 5px;
  opacity: 0.7;
  color: #797979;
  font-size: 18px;
  letter-spacing: 1px;
}

.login-form-button {
  background-color: #f7a600;
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: HCo Gotham, sans-serif;
  border-radius: 4px;
}

.login-form-button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.login-form-restore {
  margin-top: 25px;
  color: #f4a62b;
  font-family: HCo Gotham, sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 25px;
}

.login-form-restore:hover {
  opacity: 0.8;
}

.ui.fluid.button {
  background-color: #f7a600;
  color: #ffffff;
  font-weight: 400;
}

@media (max-width: 600px) {
  .login-form {
    width: 50%;
  }
}
