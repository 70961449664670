.client-dnd {
    color: rgba(34,36,38,.15);
    font-size: 16px;
    border: 2px dashed rgba(34,36,38,.15);
    border-radius: 2px;
    margin: 5px auto;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-dnd-main {
    margin-bottom: 50px;
}

 .client-dnd-main p {
    margin-bottom: 4px!important;
}


.client-dnd-main h4 {
    font-size: 18px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 500;
    color: #8C8C8C;
    margin-bottom: 3px
}

.client-dnd-main p {
    font-size: 14px;
    color: #8C8C8C;
}


.client-dnd-main-content {
    display: flex;
    flex-wrap: wrap;
}

.client-dnd-main-content div {
    padding: 8px;
    background-color: #EEEDF1;
    margin-right: 5px;
    margin-bottom: 3px;
    border-radius: 5px;
    box-sizing: border-box;
}

.client-dnd-main-content i {
    font-size: 16px;
    margin-left: 8px;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.client-dnd-main-content i:hover {
    opacity: 0.5;
}


