.dashboard-profile-logout {
    margin-top: -5px;
}

.profile-form label {
    font-family: HCo Gotham, sans-serif!important;
    color: #8C8C8C!important;
}

.profile-button {
    background-color: #F4A62B!important;
    color: white!important;
}