.search-bar-title-logo {
    font-size: 24px;
    color: black;
}

.search-bar-title-logo img {
    width: 150px;
    margin-left: 5vw;
}

