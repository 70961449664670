.wireframes-main-background {
    background-color: #252525;
}

.ui.grid {
    margin-top: 0!important;
}

.wireframes-main-back {
    padding-left: 35px;
    padding-top: 32px;
}

.wireframes-main-back-span {
    color: white;
    font-size: 24px;
    margin-left: 10px;
    position: relative;
    bottom: 2px;
}

.wireframes-main-back-span:hover {
    color: #dbdbdb;

}

.wireframes-main-title {
    color: white;
    padding-top: 120px;
    padding-left: 250px;
    height: calc(100vh - 70px);
}

.wireframes-main-title h2 {
    color: #F8BE29;
    font-size: 60px;

}

.wireframes-main-arrow {
    color: white;
    font-size: 40px!important;
    padding-left: 25px;
}

.wireframes-main-title:hover .wireframes-main-arrow {
    padding-left: 50px;
}

.wireframes-main-title p {
    font-size: 24px;
    letter-spacing: 2px;
}

.wireframes-main-list {
    margin-left: 220px;
    margin-top: 140px;
}

.wireframes-main-list:last-of-type {
    margin-bottom: 10%;
}

.wireframes-main-list-item {
    text-decoration: none;
    display: block;
    color: white;
    letter-spacing: 2px;
    font-size: 34px;
    line-height: 34px;
    padding-bottom: 35px;
}


.wireframes-main-list-item-link:hover {
    color: #a9a9a9 !important;
}


.ui.grid>.row {
    padding:0!important;
}

.wireframes-main-form {
    height: 58px;
    background: #252525;
    border:none;
    font-size: 34px;
    color: white;
    padding-bottom: 30px;
}

.wireframes-main-form::placeholder {
    color: white;
    opacity: 0.5;
}