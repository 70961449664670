.wireframes-build-dnd {
    background-color: rgb(251,251,251);
}

.wireframes-build-menu {
    background-color: #252525;
    height: 100%;

}

.wireframes-build-functions {
    margin-top: 30px;
    margin-left: 60px;
    margin-bottom: 100px;
}

.wireframes-build-functions-item {
    margin-right: 40px;
    font-size: 22px;
    font-weight: 700;
    color: black;
    opacity: 0.9;
    cursor: pointer;
}

.wireframes-build-functions-item:hover {
    opacity: 0.5;
}

.wireframes-build-container {
    height: 100%;
}

.wireframes-build-menu-nav-link {
    display: block;
    padding: 35px 40px;
    text-decoration: none;
    color: white;
    font-size: 22px;
    cursor: pointer;
}

.wireframes-build-menu-nav-link:hover {
    color: #a9a9a9 !important;
}

.wireframes-build-menu-nav {
    display: flex;
    justify-content: space-between;
}

.wireframes-build-menu-title {
    font-size: 36px;
    color: white;
    margin-left: 14%;
    margin-top: 5%;
    margin-bottom: 4%;
    cursor: pointer;

}

.wireframes-build-menu-components-list-item  {
    color: white;
    font-size: 25px;
    margin-left: 18%;
    margin-bottom: 10px!important;
    cursor: pointer;
}



.wireframes-build-menu-components-list-item:hover {
    opacity: 0.7;
}

.wireframes-build-categories-icons {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    cursor: pointer;
}

.wireframes-build-categories-icons-item {
    flex: 0 40%;
}

.wireframes-build-categories-icons-text {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
}

.wireframes-build-categories-icons-text:hover {
    opacity: 0.7;
}

.wireframes-build-container-grid {
    padding: 0px!important;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}

.my-masonry-grid_column div {
    cursor: pointer;
    margin-bottom:15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    transition: all .3s ease-in-out;
}

.my-masonry-grid_column div:hover {
    transform: scale(1.02);
}
.my-masonry-grid_column p {
    font-size: 20px;
    font-weight: 600;
    color: black!important;
    text-align: center;
}

.my-masonry-grid_column img {
    width: 100%;
    height: 100%;
}

.modal-masonry {
    width: 80% !important;

}

body > div.ui.page.modals.dimmer.transition.visible.active {
    padding-top: 30px!important;
}