.proposal-client {
    margin: 100px 26% 50px 14%;
    font-family: HCo Gotham, sans-serif!important;
}

.proposal-client h1,h2,h3,h4 {
    font-family: HCo Gotham, sans-serif!important;
}

.proposal-client-header-p {
    margin-bottom: 12px;
    font-weight: 600;
    color: #8C8C8C;
    font-size: 14px;

}

.proposal-client-header {
    margin-bottom: 120px;
    margin-left: 30%;
}

.proposal-client-header-h {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 42px;
    color: #8C8C8C;
    opacity: 0.4;
}

.proposal-client-content {
    display: flex;
}

.proposal-client-content-nav {
    width: 400px;
    height: 250px;
    position: fixed;
    top: calc(50% - 110px);
    left: 6%;
}

.proposal-client-content-nav p{
    margin-bottom: 5px;
}

.proposal-client-content-main {
    margin-left: 30%;
    width: 60%;
}

.proposal-client-content-nav-o {
    color: #F4A62B;
    font-weight: 500;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.proposal-client-content-nav-p {
    color: #bdbdbd;
    opacity: 0.4;
    font-size: 20px;
    cursor: pointer;
    line-height: 1.3em;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

.proposal-client-content-nav-p:hover {
    opacity: 0.7;
}

.proposal-client-contact {
    padding-bottom: 120px;
}

.proposal-client-contact h2 {
    font-size: 26px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 600;
    color: #8C8C8C;
    margin-bottom: 20px;

}
.proposal-client-contact-flex {
    display: flex;
    justify-content: space-between;
    margin-right: 80px;
}

.proposal-client-contact-flex-item {
    width: 60%;
}

.proposal-client-contact-flex-item h4 {
    font-size: 14px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 500;
    color: #8C8C8C;
    opacity: 0.4;
    margin-bottom: 5px;
}

.proposal-client-contact-flex-item p {
    margin-bottom: 5px;
    color: #8C8C8C;
}

.proposal-client-contact-website {
    margin-top: 10px;
    color: #8C8C8C;
    opacity: 0.4;
    font-weight: 500;
    display: inline-block;
}

.proposal-client-contact-website-span-div > p > a {
    color: #8C8C8C!important;
}

.proposal-client-contact-website-span  {
    color: #8C8C8C;
}

.proposal-templ {
    padding-bottom: 150px;
}

.proposal-templ h2 {
    font-size: 26px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 600;
    color: #8C8C8C;
    margin-bottom: 20px;
    margin-top: 60px;
}

.proposal-templ p {
    margin-bottom: 5px;
    color: #8C8C8C;
}

.proposal-templ ol {
    padding-left: 16px;
}

.proposal-templ ol li {
    padding-left: 16px;
    margin-bottom: 8px;
    color: #8C8C8C;
}

.proposal-templ ul li {
    margin-bottom: 8px;
    color: #8C8C8C;
    padding-left: 31px;
}

.proposal-templ ul {
    list-style-type: none;
    /*use padding to move list item from left to right*/
    padding-left: 0em;
}

.proposal-templ ul li:before {
    content: "—";
    position: absolute;
    /*change margin to move dash around*/
    margin-left: -2em;
}


.proposal-templ a {
    color: #F4A62B!important;
}

.proposal-cost-cont {
    width: 60%;
    border: 1px solid rgba(140,140,140,0.3);
    border-radius: 8px;
    padding: 25px 25px 15px 25px;
    margin-bottom: 30px;
}

.proposal-cost-cont h4 {
    text-transform: uppercase;
    font-size: 15px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 600;
    color: #8C8C8C;
    margin-bottom: 5px;
}

.proposal-cost-cont h4 li {
    line-height: 28px;
}

.proposal-price-total {
    font-size: 26px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 600;
    color: #8C8C8C;
    margin-bottom: 20px;
}

.q-mark {
    margin-left: 2px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.proposal-cost-flex {
    display: flex;
}

.proposal-hint {
    border-radius: 8px;
    border: 2px solid #F4A62B;
    padding: 5px;
    display: flex;
    max-width: 37%;
    height: 30%;
    color: #F4A62B;
    margin-left: 3%;
    line-break: anywhere;
}

.proposal-hint-text {
    margin: 5px 3px 5px 10px;
}

.proposal-pages {
    margin-bottom: 30px;
}

.proposal-pages h4 {
    text-transform: uppercase;
    color: #8C8C8C;
    font-family: HCo Gotham, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.proposal-pages-link {
    color: #F4A62B;
    font-weight: 500!important;
    font-family: HCo Gotham, sans-serif;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
}

.hidden-contact-client {
    font-size: 30px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 600;
    color: #8C8C8C;
    margin-bottom: 20px;
    display: none;
}

@media screen and (max-width: 1300px) {

    .proposal-client-content-nav {
        left: 3%;
    }

    .proposal-client-content-nav-p, .proposal-client-content-nav-o {
        font-size: 15px;
    }

}

@media screen and (max-width: 1050px) {

    .proposal-client {
        margin: 100px 30px 50px 15px!important;
    }

    .proposal-client-header, .proposal-client-content-main {
        margin-left: calc(30% + 100px);

    }

}

@media screen and (max-width: 700px) {

    .proposal-client-content-nav {
        display: none;
    }

    .proposal-client-header, .proposal-client-content-main {
        margin: 10px;
        width: 100%;

    }

    .proposal-client-contact-flex {
        flex-direction: column;
    }

    .proposal-client-contact-flex-item {
        margin-bottom: 20px;
    }

    .hidden-contact-client {
        display: block;
    }

}



