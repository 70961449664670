.project-overview-header {
  display: flex;
  justify-content: space-between;
  padding-top: 44px;
  padding-bottom: 30px;
  align-items: center;
  background-color: white;
}
.project-overview-header-logo {
  margin-left: 5vw;
}

.project-overview-header-logo img {
  width: 150px;
}

.project-overview-header-account {
  margin-right: 5vw;
}

.project-overview-header-account a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
}

.project-overview-header-account a:hover {
  color: rgb(0, 0, 0, 0.12);
}

.project-overview-navbar a {
  text-transform: uppercase !important;
}

.project-overview-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1000px !important;
}

.project-overview-section-content-link-form button {
  margin: 15px 0;
  border: none;
  padding: 10px 20px;
  background-color: #f4a62b;
  color: white;
  border-radius: 5px;
}

@media screen and (max-width: 1000px) {
  .project-overview-nav {
    overflow-x: scroll;
  }
  .project-proposal-header {
    flex-direction: column-reverse;
  }
  .project-proposal-header p {
    left: 80%;
  }
}

.navbar-active {
  background-color: #e4e4e4 !important;
}

.project-overview-menu a {
  width: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500;
  font-family: HCo Gotham, sans-serif;
  color: #f6f6f6;
  padding: 20px 0;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.06);
  text-align: center;
  font-size: 12px;
  border-right: 1px solid #e4e4e4;
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.1);
}

.project-overview-menu a:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.project-overview-menu a:last-of-type {
  border: none;
}

.p-o-content {
  background-color: #f2f1f1;
  padding: 0 15vw 50px 15vw;
  font-family: "HCo Gotham", sans-serif !important;
}

.project-overview-dasboard-header {
  padding: 100px 0 100px 0;
}

.project-overview-dasboard-header-titles h2 {
  font-size: 42px;
  margin: 0;
  font-family: "HCo Gotham", sans-serif;
}

.project-overview-dasboard-header-titles h2:last-of-type {
  color: #8c8c8c;
  margin-bottom: 50px;
}

.project-overview-dasboard-header-titles h2:first-of-type {
  color: #f4a62b;
}

.project-overview-dasboard-header-contact {
}

.project-overview-dasboard-header-contact p {
  margin: 0;
  font-size: 16px;
}

.project-overview-section {
  background-color: white;
  min-height: 500px;
  border-radius: 25px;
  margin-bottom: 100px;
  display: flex;
  padding: 60px;
}

.project-overview-section-header {
  width: 50%;
}

.project-overview-date-control {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-right: 10px;
  border-radius: 2px;
  color: white;
  background-color: #f4a62b;
  width: 35px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.project-overview-date-control:hover {
  opacity: 0.8;
}

.project-overview-section-header h2 {
  font-size: 35px;
  margin: 0;
  font-family: "HCo Gotham", sans-serif;
  color: #a4a3a3;
  margin-bottom: 30px;
}

.project-overview-section-header {
  width: 45%;
}
.project-overview-section-content {
  display: flex;
  flex-direction: column;
  width: 55%;
  padding-top: 10px;
  justify-content: flex-start;
}

.project-overview-section-content-link {
  margin: 15px 0;
}

.project-overview-section-content-link a,
.project-overview-section-content-link span {
  text-decoration: none;
  color: #f4a62b;
  font-size: 20px;
  font-family: "HCo Gotham", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.project-overview-section-content-link a:hover {
  opacity: 0.7;
}

.project-overview {
  height: 100%;
}

.project-overview-section-content-link-func {
  margin-top: 3px;
}

.project-overview-section-content-link-func span {
  font-size: 12px !important;
  color: #8c8c8c;
  cursor: pointer;
}

.project-overview-section-content-link-func span:first-of-type {
  margin-right: 15px;
}

.project-overview-section-content-link-func span:hover {
  opacity: 0.8;
}

.project-overview-section-content-link-form {
  margin-top: auto;
}

.project-overview-section-content-link-form input {
  display: block;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  width: 300px;
}

.project-overview-section-content-link-form input::placeholder {
  opacity: 0.4;
  padding: 5px;
}

.project-overview-section-content-link-form p {
  color: #f4a62b;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 0px;
}

.project-overview-section-content-edit-form input {
  display: block;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  width: 200px;
}

.project-overview-section-content-edit-form span {
  font-size: 12px !important;
  color: #8c8c8c;
  cursor: pointer;
  margin-top: 3px;
}
.project-overview-section-content-edit-form span:hover {
  opacity: 0.8;
}

.project-overview-section-packages {
  background-color: white;
  min-height: 500px;
  border-radius: 25px;
  margin-bottom: 100px;
  padding: 60px;
}

.project-overview-section-packages-header {
  width: 40%;
  margin-bottom: 50px;
}

.project-overview-section-packages-header h2 {
  font-size: 35px;
  margin: 0;
  font-family: "HCo Gotham", sans-serif;
  color: #a4a3a3;
  margin-bottom: 30px;
}

.project-overview-section-packages-overview {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 50px;
}

.project-overview-section-packages-overview-item div:first-of-type {
  color: #f4a62b;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px;
}

.project-overview-section-packages-overview-item div:last-of-type {
  font-size: 14px;
  font-weight: 500;
  color: #8c8c8c;
  opacity: 0.8;
}

.project-overview-section-packages-addons h3 {
  font-size: 20px;
  font-weight: 500;
  color: #8c8c8c;
  opacity: 0.8;
  margin-bottom: 15px;
  font-family: "HCo Gotham", sans-serif;
}

.project-overview-section-packages-addons-list div {
  color: #8c8c8c;
  opacity: 0.8;
}
.project-overview-section-packages-addons-list span {
  color: #f4a62b;
  font-weight: 500;
}

.project-overview-web-link {
  height: calc(100% - 182px);
  background-color: white;
}

.project-overview-web-link-content {
  width: 550px;
  margin: 0px auto 0 auto;
  padding-top: 100px;
}

.project-overview-web-link-content h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-family: "HCo Gotham", sans-serif;
  color: #8c8c8c;
  opacity: 0.9;
}

.project-overview-web-link-content p {
  color: #b2b2b2;
  font-family: "HCo Gotham", sans-serif;
  font-size: 15px;
  margin-bottom: 25px;
}

.project-overview-web-link-content-link-func {
  margin-bottom: 5px;
}

.project-overview-web-link-content-link-func span {
  font-weight: 500;
  font-size: 16px;
  color: #c3c3c3;
  padding-right: 10px;
  cursor: pointer;
}
.project-overview-web-link-content-link-func-but {
  margin-top: 40px;
  margin-bottom: 33px;
}
.project-overview-web-link-content-link-func-but span {
  font-weight: 500;
  font-size: 15px;
  color: white;
  padding: 9px 14px;
  cursor: pointer;
  background-color: #f4a62b;
  border-radius: 8px;
  margin-right: 25px;
}

.project-overview-web-link-content-link-func span:hover {
  opacity: 0.7;
}

.project-overview-web-link-content-link-func-but span:hover {
  opacity: 0.7;
}

.project-overview-web-link-content-form {
  margin: 30px 0;
}

.project-overview-web-link-content-form input[type="text"] {
  border: none;
  font-size: 16px;
  color: black;
  opacity: 0.8;
  font-weight: 500;
  font-family: "HCo Gotham", sans-serif;
  width: 100%;
}

.page-sketches-main {
  background-color: #252525;
  min-height: calc(100% - 182px);
  padding: 4vw;
  display: flex;
  font-family: HCo Gotham, sans-serif !important;
}

.page-sketches-left {
  width: 70%;
  background-color: #252525;
}

.page-sketches-right {
  width: 30%;
  padding-left: 5%;
  background-color: #252525;
  /* padding-top: 4vw; */
}

.page-sketches-left-default-text {
}

.page-sketches-left-default-text h2 {
  font-weight: 500;
  font-size: 40px;
  color: #f4a62b;
  font-family: HCo Gotham, sans-serif;
}

.page-sketches-left-default-text p {
  font-size: 20px;
  color: white;
}

.page-sketches-right span {
  text-decoration: none;
  display: block;
  color: white;
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 20px;
  cursor: pointer;
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
}

.page-sketches-right span:hover {
  color: #f4a62b !important;
}

.page-sketches-item-active {
  color: #f4a62b !important;
}

.page-sketches-right form input {
  height: 58px;
  background: #252525 !important;
  border: none;
  font-size: 24px;
  color: white;
  padding-bottom: 20px;
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
}

.page-sketches-right form input ::placeholder {
  color: white;
  opacity: 0.5;
}

.pages-sketches-left-page-header {
  margin-bottom: 25px;
}

.pages-sketches-left-page-header h2 {
  font-family: HCo Gotham, sans-serif !important;
  font-weight: 500;
  color: #8c8c8c;
  opacity: 0.9;
  font-size: 36px;
  text-transform: uppercase;
}

.pages-sketches-left-page-header p {
  color: #8c8c8c;
  opacity: 0.9;
  font-size: 16px;
  max-width: 650px;
  cursor: pointer;
}

.pages-sketches-left-page-header-form {
  margin-bottom: 20px;
}

.pages-sketches-left-page-header-form textarea {
  color: #8c8c8c;
  opacity: 0.9;
  font-size: 16px;
  width: 650px;
  cursor: pointer;
  background-color: #252525;
  font-family: HCo Gotham, sans-serif;
  border: none;
  resize: none;
  display: block;
}

.insert-component-page-sketches {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  padding: 20px 0;
  background-color: white;
}
.insert-component-page-sketches p {
  color: #8c8c8c;
  font-family: HCo Gotham, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.pages-sketches-left-page-header-func {
  display: flex;
  justify-content: space-between;
  width: 170px;
}

.pages-sketches-left-page-header-func div {
  color: #f4a62b;
  font-size: 14px;
  font-family: HCo Gotham, sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.pages-sketches-left-page-header-func div:hover {
  opacity: 0.8;
}

.pages-sketches-left-page-header-func a {
  text-decoration: none;
  color: #f4a62b;
  font-size: 18px;
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
}

.pages-sketches-left-page-images {
  display: flex;
  flex-direction: column;
  position: relative;
}

.pages-sketches-left-page-image {
  display: flex;
  align-items: center;
  transition: none;
}

.pages-sketches-left-page-image img {
  width: 75% !important;
}

.pages-sketches-left-page-image section {
}

.page-sketches-editor-form {
  height: 46px;
  margin-bottom: 1rem;
}

.page-sketches-editor-form input {
  font-family: HCo Gotham, sans-serif !important;
  font-weight: 500;
  color: #8c8c8c;
  opacity: 0.9;
  font-size: 36px;
  border: none;
  background-color: #252525;
}

.page-sketches-form-image {
  height: 19px;
}

.page-sketches-form-image input {
  width: 250px !important;
  margin-left: 30px;
  font-family: HCo Gotham, sans-serif !important;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: #252525;
  border: none;
}

.page-sketches-editor-navlink-back {
  padding: 0px 40px 40px 0px;
  text-decoration: none;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.page-sketches-upload-header {
  margin: 40px auto 10px auto;
  font-size: 24px;
  font-family: HCo Gotham, sans-serif !important;
  color: white;
  width: 80%;
}

.page-sketches-categories-icons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.my-masonry-grid_column-second {
  background-clip: padding-box;
  padding-left: 15px;
}

.my-masonry-grid_column-second div {
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  border: 1px solid #8c8c8c;
  border-radius: 5px;
}

.my-masonry-grid_column-second img {
  width: 100%;
  height: 100%;
}

.my-masonry-grid_column-second div:hover {
  transform: scale(1.02);
}

.my-masonry-grid_column-second p {
  font-size: 16px;
  color: white !important;
  text-align: center;
}

.page-sketches-delete-comp {
  color: white !important;
  font-size: 12px;
  position: absolute;
  cursor: pointer;
  left: 10px;
}

.project-proposal {
  max-width: 900px;
  margin: 0px auto;
}

.project-proposal-header {
  padding: 100px 0 50px 0;
  display: flex;
  justify-content: space-between;
}

.project-proposal-header p {
  text-decoration: none;
  font-size: 16px;
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
  color: #f4a62b;
  cursor: pointer;
  position: relative;
  bottom: 30px;
  height: 25px;
}

.content-client-view {
  text-decoration: none;
  font-size: 16px;
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
  color: #f4a62b;
  cursor: pointer;
  float: right;
  margin-right: 100px;
}

.project-proposal-header h2 {
  font-size: 42px;
  margin: 0;
  font-family: "HCo Gotham", sans-serif;
}

.project-proposal-header h2:last-of-type {
  color: #8c8c8c;
}

.project-proposal-header h2:first-of-type {
  color: #f4a62b;
}

.project-proposal-section-header {
  color: #8c8c8c;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 2rem;
  font-family: "HCo Gotham", sans-serif;
}

.form-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
  transform: scale(0.75);
}

.form-switch input {
  display: none;
}

.form-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.form-slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .form-slider {
  background-color: #f4a62b;
}

input:checked + .form-slider:before {
  transform: translateX(26px);
}

.form-slider.form-round {
  border-radius: 34px;
}

.form-slider.form-round:before {
  border-radius: 50%;
}

.form-switch-flex {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.form-switch-label {
  margin-left: 25px;
  font-weight: 500;
  color: #878787;
  font-size: 15px;
}

label {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: HCo Gotham, sans-serif !important;
}
.project-proposal-section-header-small {
  font-size: 18px;
  font-family: "HCo Gotham", sans-serif;
  margin-bottom: 3px;
  color: #878787;
}
.project-proposal-section-header-small + p {
  color: #878787;
}

.proposal-form-border {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  /*border: 1px solid rgba(0,0,0,0.1);*/
}

.proposal-form-border input {
  border-color: #e0e0e0 !important;
}

.proposal-form-border label {
  color: #878787 !important;
  margin-bottom: 0.5rem !important;
  font-size: 15px !important;
}

.proposal-branding-upload-header {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: HCo Gotham, sans-serif !important;
  margin-bottom: 4px;
  margin-left: 5px;
  color: #878787;
}

.ck.ck-icon {
  font-size: 7px !important;
  color: #737373 !important;
}

.ck-toolbar {
  background-color: white !important;
  border-top: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
  border-left: 1px solid #e0e0e0 !important;
}

.ck-content {
  border: 1px solid #e0e0e0 !important;
}

.proposal-branding-upload img {
  height: 180px;
  cursor: pointer;
}

.branding-proposal-upload-files {
  margin-left: 5px;
  margin-bottom: 10px;
  display: flex;
}

.branding-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 7px;
  margin-right: 15px;
}

.branding-border-image {
  width: 140px !important;
  height: 140px !important;
}

.branding-border-image-control {
  display: flex;
  justify-content: space-between;
}

.branding-border-image-control span {
  font-size: 11px !important;
  cursor: pointer;
  padding: 0px !important;
  font-weight: 600;
}

.branding-border-image-control span:hover {
  opacity: 0.7;
}

.create-custom-addon {
  margin-top: 10px;
  color: #f4a62b;
  margin-bottom: 10px;
}
.create-custom-addon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.custom-addon-item {
  color: #8c8c8c;
  cursor: pointer;
}

.branding-border-image-control span:hover {
  opacity: 0.7;
}

.proposal-cost-dash {
  position: relative;
  top: 8px;
  font-weight: 500;
  padding: 0 10px;
}

.proposal-pages-link {
  text-decoration: none;
  color: #f4a62b;
  font-weight: 500;
  font-family: HCo Gotham, sans-serif;
  cursor: pointer;
  font-size: 18px;
}

.proposal-pages-link:hover {
  opacity: 0.8;
  color: #f4a62b;
}

.proposal-pages-page {
  text-decoration: none;
  color: #8c8c8c;
  text-transform: uppercase;
  font-weight: 500;
  font-family: HCo Gotham, sans-serif;
  cursor: pointer;
}

.proposal-pages-page:hover {
  color: #8c8c8c;
}

.proposal-comment-form {
  margin-bottom: 20px;
  border: 4px solid #f4a62b;
  border-radius: 5px;
  width: 60%;
}

.proposal-textarea {
  resize: none !important;
  width: 100%;
  padding: 5px;
  height: 100px;
  border: none;
}

.proposal-comment-date {
  padding-right: 20px;
}

.proposal-comment {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
}

.proposal-comment-flex {
  display: flex;
  justify-content: flex-end;
}

.proposal-comment-flex {
  display: flex;
}

.proposal-comment-delete {
  color: #8c8c8c !important;
}
.proposal-comment-delete:hover {
  opacity: 0.8;
}

.revision-tasks-form #text {
  height: 150px;
  width: 100%;
  font-size: 20px;
  padding: 25px;
  resize: none;
  border: none;
}

.proposal-ph input::placeholder {
  font-size: 16px !important;
  opacity: 0.7;
}

.proposal-edit-input {
  border: none;
  display: block;
  font-family: HCo Gotham, sans-serif;
  font-size: 16px;
  width: 100%;
}

.content-add-section {
  text-align: center;
  cursor: pointer;
}

.content-add-section span {
  margin: 0 15px;
}

.content-add-section-hr {
  width: 40%;
  display: inline-block;
  position: relative;
  top: 4px;
  height: 2px;
  border: none;
  background-color: rgba(0, 0, 0, 0.15);
}

.comments-add-input-po {
  color: #a2a2a2 !important;
  font-size: 12px;
  margin-bottom: 5px;
  border: none;
}

.comment-inside-h {
  padding: 0;
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.comment-inside-t {
  color: #a2a2a2 !important;
  font-size: 12px;
  margin-bottom: 5px;
  font-family: HCo Gotham, sans-serif;
}
.comment-inside-f {
}

.comment-inside-f span {
  cursor: pointer;
  padding: 0px 10px 0px 0px;
  float: left;
  font-weight: 500;
  display: inline-block;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.7;
}

.comment-inside-f span:hover {
  opacity: 0.7;
}

.project-overview-section-content-list {
  height: 100%;
  width: 100%;
}

.project-overview-section-content-item {
  color: #8c8c8c;
  background-color: #f2f1f1;
  min-height: 30%;
  margin-bottom: 3%;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.project-overview-section-content-item h4 {
  font-family: HCo Gotham, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.project-overview-section-content-item div {
  margin: 20px 25px;
}

.project-overview-section-content-item p {
  font-family: HCo Gotham, sans-serif;
  font-size: 13px;
}

.posci-active {
  background-color: white;
  color: #f4a62b !important;
  border: 2px solid #f4a62b;
  cursor: pointer;
}

.journey-bar {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
}

.journey-bar h4 {
  margin-bottom: 0px;
  font-family: HCo Gotham, sans-serif;
}

.journey-bar-f1 {
  width: 100%;
  color: white;
  background-color: #6b6b6b;
  opacity: 0.9;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.journey-bar .journey-bar-f1 h4,
.journey-bar .journey-bar-f1 p {
  margin-left: 25px;
}
.journey-bar-f2 {
  background-color: #f4a62b;
  color: white;
  font-weight: 500;
  min-width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}

.journey-bar-f2 div {
  margin: 0 20px;
}
