.project-overview-comment-date {
    position: absolute;
    bottom: 2px;
    right: 15px;
}

.project-overview-comment-edit {
    float: right;
    font-weight: 500;
    padding-right: 2px;
    position: relative;
    bottom: 5px;
    cursor: pointer;
}

.project-overview-comment-edit:hover {
    opacity: 0.6;
}