.wireframes-build-menu-upload-custom-dnd {
    color: white;
    font-size: 18px;
    border: 1px rgba(255,255,255, 0.6) dashed;
    border-radius: 4px;
    width: 80%;
    margin: 30px auto;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wireframes-build-menu-upload-custom-header {
    margin: 40px auto 10px auto;
    font-size: 32px;
    color: white;
    width: 80%;
}

.wireframes-build-menu-upload-custom-text {
    width: 80%;
    margin: 0 auto ;
    font-size: 16px;
    color: white
}

.wireframes-build-menu-upload-custom-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.wireframes-build-menu-upload-custom-buttons button:first-child {
    margin-right: 5%;
}

.wireframes-build-menu-upload-custom-file {
    margin-top: 20px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
}