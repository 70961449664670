.dashboard-project-card-new-card {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;



}

.dashboard-project-card-new-shadow {
    border-radius: 5px!important;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.09)!important;

}



.dashboard-project-card-new-plus {
    font-size: 140px;
    color: #F4A62B;
    margin-bottom: 0;
    font-weight: 500;
}

.dashboard-project-card-new-padding {
    padding:20px;
    min-width: 270px;
}