.dashboard-project-card {
    height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dashboard-project-card-shadow {
    border-radius: 5px!important;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.09)!important;

}

.dashboard-project-new-card-bottom {
    padding: 16px 20px 16px 40px!important;
    border: none!important;
}

.dashboard-project-new-card-bottom span {
    font-size: 13px;
}

.dashboard-project-card-padding {
    padding: 20px;
    min-width: 270px!important;
}


.dashboard-project-card-title {
    color: #8C8C8C;
    transition: opacity 0.2s;
    font-size: 16px;
    font-weight: 400;
}

.dashboard-project-card-shadow:hover .dashboard-project-card-title {
    opacity: 0.8;
}