.pricing-form-addons {
    margin-bottom: 15px;
    font-family: HCo Gotham, sans-serif!important;
}

.prcing-form-addons-header {
    font-weight: 500;
}

.prcing-form-addons-addons {
    margin-bottom: 8px;
}

.prcing-form-addons-addons-item {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 16px;
}

.prcing-form-addons-addons-edit {
    font-weight: 500;
    color: #F4A62B;
    cursor: pointer;
    font-size: 12px;
    margin-left: 13px;
    text-transform: uppercase;
}

.prcing-form-addons-addons-remove {
    font-weight: 500;
    color: #F4A62B;
    cursor: pointer;
    font-size: 12px;
    margin-left: 13px;
    text-transform: uppercase;
}



.prcing-form-addons-addons-add {
    font-weight: 500;
    color: #F4A62B;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.prcing-form-addons-addons-add:hover {
    opacity: 0.8;
}