.revision-main {
    padding: 100px 20px 100px;
    max-width: 700px;
    margin: auto;
    background-color: white;
}

h4 {
    font-family: "HCo Gotham", sans-serif!important;
}

.revisions-tasks-header {
    margin-bottom: 70px;
}

.revisions-tasks-header h1 {
    font-size: 26px;
    font-weight: 500;
    color: #949494;
    font-family: "HCo Gotham", sans-serif;
    text-align: center;
}

.revisions-tasks-header div {
    font-size: 16px;
    color: #b2b2b2!important;
    margin-top: 30px;
    font-weight: 500;
    text-align: center;
}

.revision-tasks-task-ticked {
    height: 22px;
    width: 23px;
}

.revision-tasks-task-empty {
    height: 22px;
    width: 22px!important;
    border-radius: 50%;
    border: 0.17em solid #F4A62B;
}

.revision-tasks-task-icon {
    width: 54px!important;
    height: 48px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.revision-tasks-task {
    margin: 50px auto;
}

.revision-tasks-task:first-of-type {
    margin-top: 25px;
}

.revision-tasks-task span {
    float: right;
    display: inline-block;
    font-size: 11px;
    opacity: 0.7;
    padding: 5px 5px;
    cursor: pointer;
}

.revision-tasks-task span:hover {
    opacity: 0.7;
}

.reply-comment {
    font-weight: 500;
    font-size: 9px;
    padding-left: 70px;
    color: #f7a600;
    cursor: pointer;

}

.revision-tasks-task-border {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
}

.revision-tasks-task-flex {
    display: flex;
    align-items: center;
    padding: 15px 0px 15px 8px
}

.revision-tasks-task-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.revision-tasks-task-header-func span {
    width: 150px!important;
    font-weight: 500;
    font-size: 12px;
    color: #F4A62B!important;
    padding: 0px!important;

}

.revision-tasks-task-header h4 {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    margin: 0px!important;
    padding: 5px 15px 5px 10px;
    color: #6e6e6e;
}

.revision-tasks-task-header p {
    padding: 0px 15px 0px 10px;
    color: #696969!important;
    cursor: pointer;
}

.revision-tasks-task-border p {
    margin-left: auto;
    padding-right: 10px;
    order: 2;
    font-size: 11px;
    color: #a2a2a2!important;
}

.revision-tasks-form {
    border: 1px solid #F4A62B !important;
    border-radius: 2px;
    margin-top: 40px;
}

.file-attached-comment {
    font-size: 11px!important;
    color: #a2a2a2!important;
    font-weight: 400!important;
}

.revision-tasks-form #text {
    height: 120px;
    width: 100%;
    font-size: 15px;
    padding: 25px;
    resize: none;
    border: none;
    font-family: HCo Gotham, sans-serif;
    font-weight: 400;
    color: #787878;
}

.revision-tasks-form textarea::placeholder {
    font-size: 14px;
    color: #d2d2d2;
}

.revision-tasks-form-form {
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F4A62B;
}

.revision-tasks-form button {
    border: none;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    background-color: #F4A62B;
    color: white;
    cursor: pointer;
    font-family: "HCo Gotham", sans-serif;
}

.revision-tasks-form label {
    padding-left: 3px;
    color: white;
    font-size: 14px;
    font-family: "HCo Gotham", sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.revision-tasks-form label:hover {
    opacity: 0.7;
}

 #file, #filee {
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
    position: absolute;
}

.revision-tasks-task-comments {
    border-top: 1px #E7E7E7 solid;
}

.revision-tasks-task-comments-pad {
    padding-bottom: 15px;
}

.revision-tasks-task-comments-comment {
    margin: 10px 55px;
    padding: 15px;
    max-width: 500px;
}

.revision-tasks-task-comments-comment h4 {
    padding: 0;
    color:  #7f7f7f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.revision-tasks-task-comments-comment p {
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
}
.refer-task {
    font-weight: 500;
    color: #8C8C8C;
    font-size: 14px;
}
.revision-tasks-task-comments-comment p:last-of-type {
    font-weight: 400;
    word-wrap: break-word;
}

.revision-tasks-task-comments-comment-files {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    //margin-top: 7px;
}

.revision-tasks-task-comments-comment-files-flex {
    display: flex;

}



.revision-tasks-task-comments-comment-func-flex div {
    float: right;
    display: inline-block;
    font-size: 13px;
    opacity: 0.7;
    padding-right: 5px;
    cursor: pointer;
}

.revision-tasks-task-comments-comment-func-flex div:hover {
    cursor: pointer;
}

.revision-quit {
    margin-top:35px;
    padding: 10px;
    color: white!important;
    text-transform: uppercase;
    background-color: #F4A62B;
    font-weight: 500;
    border-radius: 3px;
    width: 220px;
    text-align: center;
    cursor: pointer;
}

.revision-quit:hover {
    opacity: 0.8;
}

.revision-tasks-task-comments-add {
    margin: 20px 55px 25px 55px;
}

.revision-tasks-task-comments-add form {
}


.revision-tasks-task-comments-add-input {
    border: 1px solid #e7e7f5;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    resize: none;
    font-size: 14px;
    color: #a2a2a2;
}

.revision-tasks-task-comments-add label {
    /*padding-left: 8px;*/
    /*padding-top: 7px;*/
}

.revision-tasks-task-comments-add label:hover {
    opacity: 0.7;
    cursor: pointer;
}

.revision-tasks-task-comments-add-input::placeholder {
    font-size: 14px;
    color: #a2a2a2;
}

.revision-tasks-form-files {
    display: flex;
    padding: 10px 10px;
}

.revision-tasks-form-files-hr {
    border: none;
    height: 1px;
    background-color: rgba(0,0,0,0.15);
    margin: 0 10px;

}


/*.revision-tasks-form-files div {*/
/*    margin-right: 10px;*/
/*    padding: 5px 8px;*/
/*    background-color: #EEEDF1;*/
/*    border-radius: 5px;*/
/*    font-weight: 500;*/
/*    display: flex;*/
/*    width: max-content;*/
/*    text-align: center;*/
/*}*/

/*.revision-tasks-form-files div i:hover {*/
/*    opacity: 0.7;*/

/*}*/

/*.revision-tasks-form-files div i {*/
/*    padding-top: 7px;*/
/*    cursor: pointer;*/
/*}*/

.revision-tasks-form-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #F4A62B;
}

.revision-tasks-task-comments-add-files {
    display: flex;
}

/*.revision-tasks-task-comments-add-files div {*/
/*    margin-right: 10px;*/
/*    padding: 5px 8px;*/
/*    background-color: #EEEDF1;*/
/*    border-radius: 5px;*/
/*    font-weight: 500;*/
/*}*/
.revision-tasks-task-comments-add-files-f {
    display: flex;
    align-items: center;
}

.close, .download {
    cursor: pointer;
}

.close:hover, .download:hover {
    opacity: 0.8;
}

.delete-task {
    position: relative;
    float: right;
    left: 24px;
    bottom: 13px;
    color: rgba(0,0,0,.5);
    font-size: 16px!important;
    opacity: 1!important;
    margin-top: 40px!important;
    padding-left: 10px!important;
}


/*.revision-tasks-task:hover .delete-task {*/
/*    color: rgba(0,0,0,.5);*/
/*}*/

.delete-task-comment {
    font-size: 16px!important;
    position: relative;
    float: right;
    bottom: 12px;
    left: 12px;
    color: white
}

.revision-tasks-task-comments-comment:hover .delete-task-comment {
    color: rgba(0,0,0,.5);
}

.revision-tasks-task-edit-form {
    border: none;
    font-size: 14px;
    width: 100%;
    color: #8C8C8C;
    font-weight: 400;
    font-family: HCo Gotham, sans-serif;
    padding: 10px 15px 10px 10px;
}

.revision-tasks-comment-edit-form {
    font-size: 14px;
    color: #696969!important;
    margin-bottom: 5px;
    border: none;
    width: 100%;
    height: 19px;
    font-family: HCo Gotham, sans-serif;

}

.revision-tasks-form-control-func {
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.revisions-notify {
    text-align: center;
    padding: 25px 0;
    font-weight: 500;
    color: #8C8C8C;
    background-color: rgba(0,0,0,.05);
    font-size: 20px;
    margin-bottom: 20px;
}


.revisions-notify span {
    text-decoration: underline;
    cursor: pointer;
}

.revisions-notify span:hover {
    opacity: 0.8;
}

.previous-sessions {
    margin-top: 50px;
}

.previous-sessions-header {
    cursor: pointer;
}

.previous-sessions-header span {
    font-weight: 500;
    font-size: 16px;
    color: #969696;
}

.previous-sessions-header i {
    position: relative;
    bottom: 1px;
    left: 2px;
    font-size: 12px;
}