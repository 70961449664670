.search-bar-container {
  width: 100% !important;
  height: 50px !important;
  position: relative;
}

.search-bar-container input {
  background-color: #f6f6f6 !important;
}

.search-bar-container i {
  position: absolute;
  left: 0;
}

.search-bar {
  border-radius: 10px !important;
  border: none !important;
  background-color: #f1f3f4 !important;
  padding-left: 40px !important;
}

.search-bar-flex {
  align-items: center !important;
  justify-content: space-between;
  padding-bottom: 20px !important;
}

.search-bar-nav {
  display: flex;
  justify-content: space-around;
  margin-right: 5vw;
}

.search-bar-nav a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
}

.search-bar-nav a:hover {
  color: rgba(0, 0, 0, 0.8);
}

element.style {
}
.ui.menu:last-child {
  margin-bottom: 0;
}
.ui.menu:first-child {
  margin-top: 0;
}
.ui.menu {
  box-shadow: none;
  border: solid 1px #f7a600;
  background-color: #f7a600;
}

.ui.menu .item > i.dropdown.icon,
.ui.dropdown > .text {
  color: white;
}
