.wireframes-build-add-desc-text {
    width: 100%;
    display: block;
    height: 120px;
    border-radius: 5px;
    font-size: 16px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    resize: none;
}

.wireframes-build-add-desc-text::placeholder {
    font-size: 16px!important;
}

.wireframes-build-add-desc-button {
    display: inline-block!important;
    margin-right: 10%!important;
    margin-top: 12px!important;
}