.client-input  {
    margin-bottom: 50px;
}

 .client-input p {
    margin-bottom: 4px!important;
}

.client-input h4 {
    font-size: 18px;
    font-family: HCo Gotham, sans-serif;
    font-weight: 500;
    color: #8C8C8C;
    margin-bottom: 3px
}

.client-input p:first-of-type {
    font-size: 14px;
    color: #8C8C8C;
}

.client-input-input {
    /*border-top: 10px solid #EEEDF1;*/
    /*border-left: 10px solid #EEEDF1;*/
    /*border-right: 10px solid #EEEDF1;*/
    /*border-bottom: 30px solid #EEEDF1;*/
    padding: 10px 10px 30px 10px;
    border: 1px solid #EEEDF1;
    border-radius: 5px;
}

.client-input-counter {
    font-size: 12px!important;
    font-family: HCo Gotham, sans-serif;
    position: relative;
    bottom: 25px;
    float: right;
    right: 20px;
}

input {
    outline: none!important;
}

.client-input .ck-editor {
    /*border-top: 10px solid #EEEDF1!important;*/
    /*border-left: 10px solid #EEEDF1!important;*/
    /*border-right: 10px solid #EEEDF1!important;*/
    /*border-bottom: 30px solid #EEEDF1!important;*/
    padding: 10px 10px 30px 10px;
    border: 1px solid #EEEDF1;
    border-radius: 5px!important;
}

.client-input .ck-editor p {
    font-size: 16px!important;
}

.client-input .ck-editor p:first-of-type {
    margin-top: 4px!important;
}

.ck-content {
    min-height: 120px!important;
}