
.dashboard-navbar {
   height: 55px!important;
    background-color: #f6f6f6!important;
    box-shadow: none!important;
    border:none!important;

}
.dashboard-navbar a {
    font-weight: 500!important;
    color: rgb(100,100,100)!important;
    font-family: "HCo Gotham", sans-serif!important;
}

.dashboard-navbar a:before {
    width: 0!important;
}