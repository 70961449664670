.notifications-cont {
    width: 80%;
    margin: 80px auto;
    min-height: 600px;
    display: flex;
}

.notifications-panel-left {
    width: 30%;
    height: 100%;
    margin: 10px 200px 10px 10px;

}

.notifications-panel-right {
    width: 60%;
    height: 100%;
    margin: 10px;
}

.notifications-panel-list {
    margin: 10px;
}
.notifications-panel-list-item {
    margin-bottom: 15px;
    height: 41px;
    cursor: pointer;
}

.notifications-panel-list-item span {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #8C8C8C;
    padding: 10px 30px;
    background-color: #F6F6F6;
    border-radius: 25px;
}

.panel-list-active, .notifications-panel-list-item span:hover {
    background-color: #F4A62B!important;
    color: white!important;
}

.notifications-list-item {
    display: flex;
    padding: 15px 30px;
    margin-bottom: 15px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.08)!important;

}

.notifications-list-item img {
    height: 50px;
    cursor: pointer;
}

.notifications-list-item-header {
    color: #8C8C8C;
    font-size: 16px!important;
}

.notifications-list-item-header h5 {
    font-family: HCo Gotham, sans-serif;
    margin-bottom: 5px;
    font-weight: 500;
}