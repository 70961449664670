#root {
    height: 100%;
    margin: 0!important;
    padding: 0!important;
}

body {
    margin: 0!important;
    background-color: rgb(255,255,255);
    height: 100%;
    padding: 0!important;
    font-family: "HCo Gotham", sans-serif!important;
}

html {
    margin: 0!important;
    height: 100%;
    padding: 0!important;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

.form-container {
    margin-top: 40px!important;
}

.button-container {
    margin-top: 20px;
}

.dashboard-table th:last-of-type {
    text-align: center!important;
    width: 190px;
}

.dashboard {
    align-items: center;
    margin: 0 auto;
    min-width: 1200px;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Thin_Web.woff2') format('woff2'),
    url('../woff/Gotham-Thin_Web.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-ThinItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-ThinItalic_Web.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-XLight_Web.woff2') format('woff2'),
    url('../woff/Gotham-XLight_Web.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-XLightItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-XLightItalic_Web.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Light_Web.woff2') format('woff2'),
    url('../woff/Gotham-Light_Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-LightItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-LightItalic_Web.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Book_Web.woff2') format('woff2'),
    url('../woff/Gotham-Book_Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-BookItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-BookItalic_Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Medium_Web.woff2') format('woff2'),
    url('../woff/Gotham-Medium_Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-MediumItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-MediumItalic_Web.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Bold_Web.woff2') format('woff2'),
    url('../woff/Gotham-Bold_Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-BoldItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-BoldItalic_Web.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Black_Web.woff2') format('woff2'),
    url('../woff/Gotham-Black_Web.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-BlackItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-BlackItalic_Web.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-Ultra_Web.woff2') format('woff2'),
    url('../woff/Gotham-Ultra_Web.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham';
    src:url('../woff2/Gotham-UltraItalic_Web.woff2') format('woff2'),
    url('../woff/Gotham-UltraItalic_Web.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
