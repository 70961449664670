.dnd-zone {
  width: 80%;
  margin: 0 auto 50px;
}

.dnd-zone-item {
  display: flex;
  align-items: center;
  position: relative;
}
.dnd-zone-item i:first-of-type {
  position: absolute;
  bottom: calc(100% - 22px);
  left: 79%;
  color: #404040;
}

.dnd-zone-item-image {
  width: 80%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06) !important;
}

.dnd-zone-item-text {
  display: none;
  max-width: 220px !important;
  margin-left: 30px;
  font-family: HCo Gotham, sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 0.9em;
  color: white;
  background-color: #252525;
  border: none;
}

.dnd-zone-item-form {
  background: rgb(251, 251, 251);
  border: none;
  font-size: 18px;
  color: black;
  margin-left: 30px;
  margin-bottom: 1rem;
}

.dnd-zone-header {
  margin-left: 10%;
  color: black;
  opacity: 0.9;
  font-size: 34px;
  cursor: pointer;
}

.dnd-zone-form {
  background: rgb(251, 251, 251);
  border: none;
  margin-left: 10%;
  font-size: 34px;
  font-weight: 700;
  color: black;
  margin-bottom: 14px;
  opacity: 0.9;
}

.dnd-zone-item-image-white {
  width: 80%;
  border-right: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;
}

.wireframe-arrow {
  font-size: 18px !important;
  position: relative;
  left: 5px;
  bottom: 8px;
  cursor: pointer;
}

.wireframe-arrow:hover {
  opacity: 0.7;
}

.ui.dropdown {
  display: flex;
}
