.form-input-password-icon {
    font-size: 20px!important;
    color: grey;
    float: right;
    position: relative;
    bottom: 29px;
    right: 10px;
    padding: 2px;
    cursor: pointer;
    transition: opacity 0.2s;
}

.form-input-password-icon:hover {
    opacity: 0.8;
}