.client-content-header {
    display: flex;
    width: 50%;
    margin: 40px auto 30px auto;
    align-items: center;
    color: black;
}

.client-content-header h1 {
    font-size: 40px;
}

.client-content-header p {
    font-size: 18px;
}

.client-content-form {

    margin: 0 auto 30px auto;
    align-items: center;
    color: black;
}

.client-content-form-label {

    text-transform: uppercase;
    font-size: 28px;
    margin-top: 50px;
    font-weight: 500;
    color: #8C8C8C;
    font-family: HCo Gotham, sans-serif;
    margin-bottom: 20px!important;
}

.page-sketches-client-desc {
    color: white;
    font-size: 13px;
    margin-bottom: 30px;
}



@media screen and (max-width: 1500px) {
    .content-nav-sidekick {
        display: none;
    }

}