.content-manager {
  background-color: white !important;
  display: flex;
  width: 100%;
  padding: 50px;
  height: calc(100% - 180px);
}

.content-manager-top {
  padding-top: 20px;
  padding-bottom: 25px;
  border-bottom: 2px solid lightgray;
}

.content-manager-top img {
  position: relative;
  top: 3px;
}

.content-manager-top a {
  padding-left: 30px;
  text-decoration: none !important;
  font-size: 20px;
  color: black !important;
}

.content-manager-top a:hover {
  opacity: 0.6;
}

.content-manager-side .field {
  margin-top: 30px !important;
}

.content-manager-side {
  width: 30vw;
  min-width: 550px;
}

.content-manager-main {
  width: 60%;
}

.content-manager-side h2,
.content-manager-main h2 {
  color: black;
  padding-top: 50px;
  padding-left: 50px;
  margin-bottom: 30px;
}

.content-manager-side .w25 {
  width: 25% !important;
}

.content-manager-side form {
  padding-left: 50px;
  padding-right: 100px;
}

.content-manager-side form textarea {
  resize: none !important;
  height: 150px;
}

.content-manager-main-list {
  margin: 50px 150px 50px 50px;
}

.content-manager-main-label {
  font-size: 24px;
  color: #8c8c8c;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.content-manager-main-label:hover .content-manager-edit {
  opacity: 1 !important;
}

.content-manager-main-label span {
  cursor: pointer;
}

.content-manager-main-item {
  margin-bottom: 25px !important;
  padding: 20px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-manager-main-item h4 {
  font-size: 18px;
  color: #8c8c8c;
  font-weight: 500;
  font-family: "HCo Gotham", sans-serif;
  margin-bottom: 0px;
  cursor: pointer;
}

.content-manager-main-item-func {
  color: #f4a62b;
  cursor: pointer;
}

.content-manager-main-item-func:hover {
  opacity: 0.8;
}

.content-manager-main-item p {
  color: #8c8c8c;
  font-size: 15px;
}

.content-manager-main-item img {
  width: 100%;
}

.content-manager-edit {
  padding-left: 10px !important;
  font-size: 16px !important;
  cursor: pointer;
  float: right;
  color: #f4a62b;
  position: relative;
  bottom: 5px;
  opacity: 0 !important;
}

.content-manager-edit:hover {
  opacity: 0.6;
}

.content-manager-main-list-flex {
  display: flex;
  align-items: center;
}

.show-content {
  color: black !important;
  margin-left: 25px;
}

.show-content:hover {
  opacity: 0.6;
}

.ui.search.dropdown > .text {
  color: #8c8c8c;
}
